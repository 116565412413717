import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Contact from "../../components/sections/Contact"
import Services from '../../components/sections/Services'

const NetworkEngineer = () => {
  return (
    <>
    <SEO title="vacancies"/>
    <Layout>
        <article className='co-page'>
                <section className="typical__padding co-bullet">
                    <div className="section-container">
                        <h2 className="section-title">Network Engineer</h2>
                        <p className="co-bullet-info">
                            We are a team of payment technologies seeking passionate professionals to reach new heights. DigiMeth is currently in search of a <b>Network Engineer.</b>
                        </p>
                        <div className="co-vacancies--grid">
                            <h3 className='co-bullet-title'>About DigiMeth</h3>
                            <p className="co-bullet-info">
                                Digital Method is a software development company specialising in payment technology. Our mission is to provide fast, high-quality and diverse online payment experience to our partners and their customers. We provide international and local payments for all business types.
                            </p>
                            <h3 className='co-bullet-title'>What you`ll be doing</h3>
                            <ul className='co-bullet-list'>
                                <li>Designing and implementing network solutions based on physical and virtual equipment.</li>
                                <li>Auditing, configuring, and troubleshooting network equipment.</li>
                                <li>Configuring and maintaining DNS, DHCP (Linux/Mikrotik).</li>
                                <li>Configuring and maintaining Mikrotik devices.</li>
                                <li>Establishing new sites and VPN tunnels.</li>
                                <li>Assisting users in resolving network issues.</li>
                                <li>Conducting network security audits and implementing network protection solutions.</li>
                            </ul>

                            <h3 className='co-bullet-title'>What you`ll need</h3>
                            <ul className='co-bullet-list'>
                                <li>Experience with network equipment (Mikrotik is a must).</li>
                                <li>Knowledge of network technologies, construction principles, routing, and functioning.</li>
                                <li>Understanding of the OSI model.</li>
                                <li>Knowledge and understanding of network security.</li>
                                <li>Experience in developing and implementing network infrastructure.</li>
                                <li>Knowledge and understanding of VPN technologies (openvpn, l2tp, mpd, wireguard, ipsec).</li>
                                <li>Knowledge and understanding of dynamic routing principles (ospf, bgp).</li>
                                <li>Experience in Linux administration.</li>
                            </ul>

                            <h3 className='co-bullet-title'>Nice to have</h3>
                            <ul className='co-bullet-list'>
                                <li>Experience in building virtual networks in AWS.</li>
                            </ul>

                            <p className="co-bullet-info">Work schedule - 5/2, 09:00-18:00 (aligned with banking system operations).</p>

                            <div className='co-join-container'>
                                <span>If you are interested in working with us, send your CV here</span>{' '}
                                <a href="mailto:contact@digimeth.net">contact@digimeth.net</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Services title="Work at Digimeth Feels Perfect!" desc="Our goal is to create fantastic payment solutions:" />
                <Contact/>
            </article>
        </Layout>
    </>
  )
}

export default NetworkEngineer